<template>
  <AllArtisans />
</template>

<script>
import AllArtisans from "@/view/pages/builder/AllArtisans.vue";

export default {
  name: "AllArtisansStaticPage",
  components: {
    AllArtisans
  }
}
</script>
